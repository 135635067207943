import request from '@/utils/request'

export function listPage(params) {
    return request({
        method:'get',
        url:'/api/shopDataSynchronization/listPage',
        params
    });
}

// 查询初始化
export function pageInit() {
    return request({
        url:'/api/shopDataSynchronization/pageInit',
        method:'get'
    });
}